import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';

interface BannerCarouselProps {
  banners: string[];
  autoPlayInterval?: number;
  isAppBrowser?: boolean;
}

const BannerCarousel: React.FC<BannerCarouselProps> = ({ 
  banners,
  autoPlayInterval = 5000,
  isAppBrowser = false
}) => {
  const handleBannerClick = () => {
    if (!isAppBrowser) {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
        window.location.href = 'https://apple.co/40RjcWh';
      } else if (userAgent.includes('android')) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.quickcompare.app&hl=en';
      }
    }
  };

  return (
    <Swiper
      modules={[Autoplay]}
      spaceBetween={8}
      slidesPerView={1.05}
      autoplay={{
        delay: autoPlayInterval,
        disableOnInteraction: false,
      }}
      className="aspect-[17/6] w-full"
      style={{ paddingLeft: '16px', paddingRight: '16px' }}
    >
      {banners.map((banner, index) => (
        <SwiperSlide key={index}>
          <img
            src={banner}
            alt={`Banner ${index + 1}`}
            className="w-full h-full object-cover rounded-lg cursor-pointer"
            onClick={handleBannerClick}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BannerCarousel;