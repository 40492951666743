import React from "react";
import { IconClock, IconSearch } from "@tabler/icons-react";
import { ProductSearchSuggestion } from "../../api/getProductSearchSuggestions.ts";
import GradiantSeparator from "../GradiantSeparator.tsx";

const AutoSuggestList = ({
  query,
  suggestions,
  headerHeight,
  onClick,
}: {
  query: string;
  suggestions: ProductSearchSuggestion[];
  headerHeight: number;
  onClick: (query: string) => void;
}) => {
  return (
    <ul
      className="absolute z-20 w-full overflow-hidden bg-bg px-4"
      style={{
        top: headerHeight,
        height: `calc(100vh - ${headerHeight}px)`,
      }}>
      {suggestions.map((suggestion: ProductSearchSuggestion) => (
        <li key={suggestion.name} className="flex flex-col gap-2">
          <button
            onClick={() => onClick(suggestion.name)}
            className="flex w-full items-center justify-between bg-bg px-1 py-2 hover:bg-bg">
            <div className="flex items-center gap-2">
              {suggestion.image ? (
                <img src={suggestion.image} alt={suggestion.name} className="h-8 w-8 rounded" />
              ) : (
                <IconClock className="py-2 h-8 w-8 text-text-light" />
              )}
              <p className="text-sm">{suggestion.name}</p>
            </div>
            <IconSearch className="mr-2 h-5 w-5 text-gray-400" />
          </button>
          <GradiantSeparator />
        </li>
      ))}
      {query.length > 2 && (
        <li key="see-all" className="flex flex-col gap-2 px-1 py-2">
          <button
            onClick={() => onClick(query)}
            className="flex w-full items-center justify-between px-1 py-2 bg-bg hover:bg-bg">
            <div className="flex items-center gap-2">
              <p className="text-sm font-bold text-text">See All</p>
            </div>
          </button>
          <div className="h-[0.75px] grow bg-gradient-to-r from-bg-dark to-bg"></div>
        </li>
      )}
    </ul>
  );
};

export default AutoSuggestList;
