import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import GroupListItem from "../components/GroupListItem.tsx";
import { useGeolocation } from "../context/GeolocationContext.tsx";
import GlobalHeader from "../components/GlobalHeader/GlobalHeader.tsx";
import getGroupedProducts, { GroupedProducts } from "../api/getGroupedProducts.ts";
import LootieLoader from "../components/LootieLoader.tsx";

export default function GroupListPage() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([] as GroupedProducts[]);
  const [searchParams] = useSearchParams();
  const { locateMe, geolocation, isLocating } = useGeolocation();
  var query = searchParams.get("q") || "";
  var text = searchParams.get("text") || "";
  var url = searchParams.get("url") || "";
  if (query.length === 0) {
    if (text.length > 0) {
      text = removeUrls(text);

      if (text.includes("Check out this product on Zepto!")) {
        text = text.replace("Check out this product on Zepto!", "");
      } else if (text.includes("Check out this product on blinkit-")) {
        text = text.replace("Check out this product on blinkit-", "");
      } else if (text.includes("on Swiggy Instamart:")) {
        text = text.replace("on Swiggy Instamart:", "");
        text = text.replace("Check out ", "");
      } else if (text.includes("on Flipkart")) {
        text = text.replace("on Flipkart", "");
        text = text.replace("Take a look at this ", "");
      }
      text = text.trim();
      query = text;

    } else if (url.length > 0) {
      query = url;
    }
  }

  useEffect(() => {
    if (!geolocation) {
      locateMe();
    }
  }, [geolocation, locateMe]);

  useEffect(() => {
    if (!geolocation) {
      return;
    }
    
    setLoading(true);

    getGroupedProducts(geolocation, query)
      .then((data) => {
        data.sort((a, b) => b.data.length - a.data.length);
        setProducts(data);
      })
      .catch((error) => console.error("Error fetching products:", error))
      .finally(() => setLoading(false));
  }, [query, geolocation]);

  return (
    <>
      <GlobalHeader />
      <div className="mt-36 overflow-y-scroll">
      {loading || isLocating ? (
        <LootieLoader />
      ) : products.length ? (
        <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 px-2 pb-40">
          {products.map((product, index) => (
            <GroupListItem key={index} product={product.data} detailView={false}/>
          ))}
        </div>
      ) : (
        <div className="flex h-full flex-col items-center justify-center">
          <h1 className="text-center text-2xl font-bold">No products found</h1>
          <p className="text-center text-gray-500">
            {"Try searching for something else or "}
            <br />
            <Link to="/geolocation" className="text-blue-500 underline">
              {"change your location"}
            </Link>
            {"."}
          </p>
        </div>
      )}
      </div>
    </>
  );
}

function removeUrls(input: string): string {
  // Regular expression to match URLs
  const urlPattern = /https?:\/\/[^\s]+/g;
  
  // Replace all occurrences of URLs with an empty string
  return input.replace(urlPattern, "").trim();
}
