import React from 'react';
import Lottie from 'lottie-react';
import loaderAnimation from '../assets/animations/loader.json';

const LootieLoader = () => {
  return (
    <div className='flex items-center justify-center w-full'>
        <div className="flex items-center justify-center h-96 max-w-96">
        <Lottie
            animationData={loaderAnimation}
            loop={true}
        />
        </div>
    </div>
  );
};

export default LootieLoader;