import React, { useEffect, useState, useMemo } from "react";
import { useGeolocation } from "../context/GeolocationContext.tsx";
import getHomePageData, { HomePageDetailsResponse } from "../api/getHomePageDetails.ts";
import Loader from "../components/Loader.tsx";
import GeolocationNotAvailable from "../components/GeolocationNotAvailable.tsx";
import QuickSearchSection from "../components/QuickSearchSection.tsx";
import ServiceProviderSection from "../components/ServiceProviderSection.tsx";
import GlobalHeader from "../components/GlobalHeader/GlobalHeader.tsx";
import '../styles/globals.css'
import Footer from "../components/Footer/Footer.tsx";
import BannerCarousel from '../components/BannerCarousel/BannerCarousel.tsx';
import TrendingSearchSection from "../components/TrendingSearchSection.tsx";

const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const { locateMe, geolocation, isLocating } = useGeolocation();
  const [homePageDetails, setHomePageDetails] = useState<HomePageDetailsResponse | null>(null);
  const storedRecentSearches = localStorage.getItem("recentSearches") || "[]";
  const recentSearches = JSON.parse(storedRecentSearches).reverse();
  // console.log("url", process.env.API_URL);
  // const homeProducts = HomeProducts;

  const isAppBrowser = useMemo(() => {
    const userAgent = navigator.userAgent;
    const standalone = (window.navigator as any).standalone;
    const iOSPlatforms = ['iPhone', 'iPad', 'iPod'];
    const isIOS = iOSPlatforms.some(platform => userAgent.includes(platform));
    const isAndroid = /Android/i.test(userAgent);

    if (isIOS) {
      if (standalone === undefined) {
        // WKWebView doesn't support standalone mode
        return false;
      } else if (userAgent.includes('Safari') && !standalone) {
        // mobile safari
        return false;
      } else {
        // iOS APP
        return true;
      }
    } else if (isAndroid) {
      if (userAgent.includes('wv')) {
        // Android WebView
        return true;
      } else if (userAgent.includes('Chrome')) {
        // Chrome browser
        return false;
      }
    }
    // Not iOS or Android
    return false;
  }, []); // Empty dependency array means this will compute only once
  
  useEffect(() => {
    if (!geolocation) {
      locateMe();
    }
  }, [geolocation, locateMe]);

  useEffect(() => {
    if (!geolocation) {
      return;
    }

    setLoading(true);
    getHomePageData(geolocation.latitude, geolocation.longitude)
      .then(setHomePageDetails)
      .catch((error) => {
        console.error("Error fetching home page data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [geolocation]);

  const banners = [
    "https://qcsearch.s3.ap-south-1.amazonaws.com/banners/b1.png",
    "https://qcsearch.s3.ap-south-1.amazonaws.com/banners/b2.png",
    "https://qcsearch.s3.ap-south-1.amazonaws.com/banners/b3.png",
    "https://qcsearch.s3.ap-south-1.amazonaws.com/banners/b4.png",
    // "https://qcsearch.s3.ap-south-1.amazonaws.com/banners/b5.png",
    // Add more banner URLs as needed
  ];

  return (
    <>
      <GlobalHeader />
      <div className="mt-32 relative z-0">
        {loading || isLocating ? (
          <Loader />
        ) : !geolocation ? (
          <GeolocationNotAvailable />
        ) : homePageDetails ? (
          <>
            <div>
              <div className="py-2"></div>
              <ServiceProviderSection eta={homePageDetails.eta} />
              <div className="py-2"></div>
              <div className="overflow-visible relative">
                <BannerCarousel banners={banners} autoPlayInterval={20000} isAppBrowser={isAppBrowser}/>
              </div>
              <div className="py-2"></div>
              <QuickSearchSection sectionTitle="Recent Search" searches={recentSearches} />
              <TrendingSearchSection sectionTitle="Trending Items" searches={homePageDetails.trendingItems} />
              {/* <div className="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 px-4 pb-4">
                {homeProducts.map((product, index) => (
                  <GroupListItem key={index} product={product.data} />
              ))}
              </div> */}
            </div>
            {!isAppBrowser ? <>
              <div className="h-4 px-4">
              </div>
              <Footer />
              <div className="h-32 bg-white"></div>
              </> : <div className="h-32"></div>}
            
          </>
        ) : (
          <></>
        )}
      </div>
      {/* {!loading && !isLocating &&
        <FeedbackWidget />
      } */}
    </>    
  );
};

export default HomePage;
