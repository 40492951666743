import { API_CONSTANT } from "./APIConstants.ts";

export interface HomePageDetailsResponse {
  eta: Eta[];
  trending: string[];
  trendingItems: TrendingItems[];
}

export interface TrendingItems {
  name: string;
  image: string;
}

export interface Eta {
  eta: string;
  image: string;
  platform: string;
  url: string;
  open: boolean;
}

export default async function getHomePageData(
  latitude: number,
  longitude: number,
): Promise<HomePageDetailsResponse> {
  const response = await fetch(
    `${API_CONSTANT.API_URL}?lat=${latitude}&lon=${longitude}&type=home`,
  );
  const data = await response.json();
  return data;
}
